import React, { useState, useEffect } from "react";
import './reservationTickets.css'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import {
    selectSelectedStop,
    selectSelectedRoute,
    selectSeat,
} from '../../features/tripDetails/tripDetailsSlice';
import { selectRoutes } from "../../features/routes/routesSlice";
import {
    selectId,
    selectRole,
    changeId,
    changeName,
    changeNumber,
    changeRole,
    changeBonuces,
    selectBonuses,
    changeIsBlocked,
} from '../../features/user/userSlice';
import { selectDate } from "../../features/tripDetails/tripDetailsSlice";
import { getByDay } from "../../http/tripsApi";
import { createOrder, getAllByDay } from "../../http/ordersApi";
import { payByPoints, loginUser, registration, getOneByPhone, getSMSCredentials } from "../../http/userApi";
import { createCacheReserved } from "../../http/cacheReservedApi";
import { getAllSet } from "../../http/settingsApi";

import Modal from "../UI/Modal/Modal";

function ReservationTickets() {
    const dispatch = useDispatch();

    const stop = useSelector(selectSelectedStop);
    const userId = useSelector(selectId);
    const points = useSelector(selectBonuses);
    const stopName = stop.name;
    const routes = useSelector(selectRoutes);
    const route = useSelector(selectSelectedRoute);
    const routeName = route.name;
    const seat = useSelector(selectSeat);
    const role = useSelector(selectRole);
    const isLogged = !!useSelector(selectRole);

    const [isToShow, setIsToShow] = useState('');
    const [numberFromDispetcher, setNumberFromDispetcher] = useState('');
    const [trips, setTrips] = useState([]);
    const [comment, setComment] = useState('');
    const [disabledArr, setDisabledArr] = useState([]);
    const [paymentCache, setPaymentCache] = useState(true);
    const [paymentPoints, setPaymentPoints] = useState(false);
    const [numberInputValue, setNumberInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [numberInput, setNumberInput] = useState('');
    const [isShowSMS, setIsShowSMS] = useState(false);
    const [SMSInput, setSMSInput] = useState('');
    const [isShowContinue, setIsShowContinue] = useState(true);
    const [codeSMS, setCodeSMS] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [isShowLoginBtn, setIsShowLoginBtn] = useState(false);
    const [isShowNameInput, setIsShowNameInput] = useState(false);
    const [isShowSureNameInput, setIsShowSureNameInput] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const [sureNameInput, setSureNameInput] = useState('');
    const [isShowContinueReg, setIsShowContinueReg] = useState(false);
    const [passengerName, setPassengerName] = useState('');
    const [showModal, setShowModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [isLoading, setIsLoading] = useState(true);

    const currentDate = useSelector(selectDate);
    const currentDay = currentDate.split('.')[0]
    const currentMonth = currentDate.split('.')[1]
    const currentYear = currentDate.split('.')[2]

    const [smsUsername, setSmsUsername] = useState('');
    const [smsPassword, setSmsPassword] = useState('');
    const [priceValue, setPriceValue] = useState(0);
    const [UnSuccessValue, setUnSuccessValue] = useState(0);
    const [SuccessValue, setSuccessValue] = useState(0);
    const [FreeRideValue, setFreeRideValue] = useState(0);

    useEffect(() => {
        fetchTripsAvailable()
    }, [currentDate, seat])


    useEffect(() => {
        const fetchSMSCredentials = async () => {
            try {
                const smsCredentials = await getSMSCredentials();
                setSmsUsername(smsCredentials.username);
                setSmsPassword(smsCredentials.password);
            } catch (error) {
                console.error('Error fetching SMS credentials:', error);
            }
        };
        const fetchDataSet = async () => {
            try {
                const data = await getAllSet();
                const price = data.find(item => item.typeField === 'Цена')?.valueField || 0;
                const UnSuccess = data.find(item => item.typeField === 'Поездку отменил клиент менее чем за 2 часа')?.valueField || 0;
                const Success = data.find(item => item.typeField === 'Успешная поездка')?.valueField || 0;
                const FreeRide = data.find(item => item.typeField === 'Бесплатная поездка')?.valueField || 0;
                setUnSuccessValue(UnSuccess)
                setSuccessValue(Success);
                setPriceValue(price);
                setFreeRideValue(FreeRide);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataSet();
        fetchSMSCredentials();
    }, []);

    const fetchTripsAvailable = async () => {
        setIsLoading(true);
        try {
            const orders = await getAllByDay(new Date(currentYear, currentMonth - 1, currentDay), new Date(currentYear, currentMonth - 1, +currentDay + 1));
            setDisabledArr([]);
    
            const tripIds = new Set();
    
            // Create a mapping of tripId to withListSeats from tripsData
            const tripsData = await getByDay(new Date(currentYear, currentMonth - 1, currentDay), new Date(currentYear, currentMonth - 1, +currentDay + 1), route.id);
            const seatsMap = {};
            
            tripsData.forEach(trip => {
                seatsMap[trip.id] = trip.withListSeats || 0; // Default to 0 if null
            });
    
            console.log("Orders:", orders);
            
            orders.forEach(order => {
                // Calculate totalCount for the current tripId
                const totalCount = orders.filter(obj => obj.tripId === order.tripId)
                                          .reduce((sum, elem) => sum + elem.seats, 0) + (seatsMap[order.tripId] || 0);
    
                if (totalCount + seat > 15) {
                    tripIds.add(order.tripId);
                }
            });
    
            setDisabledArr(Array.from(tripIds));
            setTrips(tripsData);
        } catch (error) {
            console.error(error);
            // Show a user-friendly error message here
        } finally {
            setIsLoading(false);
        }
    };
    
    

    const handleChangeNumber = (event) => {
        setNumberFromDispetcher(event.target.value);
    };

    const openModal = (str) => {
        setModalMessage(str)
        setShowModal(true)
    }

    const closeModal = (boolean) => {
        setShowModal(boolean)
        setModalMessage('')
    }

    const handleReserveDispetcher = async (trip) => {

        let tripStart = new Date(trip.dateTimeStart)
        tripStart = moment(tripStart, "YYYY-MM-DD HH:mm:ss");
        tripStart.add(stop.timeOffSet, "minutes");
        tripStart = tripStart.format("YYYY-MM-DD HH:mm:ss")
        let data

        try {
            data = await getOneByPhone(`+375${numberFromDispetcher}`)
            if (data.isBlocked) {
                openModal('Пользователь с таким номером заблокирован. Бронирование производиться не будет')
                return
            }
            //setPassengerName(data.name)
        } catch (error) {
            console.log(error.response.data.message);
        }

        if (!data) {
            try {
                data = await registration(passengerName, `+375${numberFromDispetcher}`)
            } catch (error) {
                console.log(error.response.data.message);
            }
        }

        try {

            const order = await createOrder({ dateTimeStart: tripStart, seats: seat, comment: comment, startStopId: stop.id, tripId: trip.id, userId: data.id, paymentPoints: paymentPoints, typePay: paymentPoints ? 'Баллами' : 'Наличными' })
            console.log(order);
            openModal('Поездка забронирована успешно.')

        } catch (error) {
            alert(error.response.data.message);
        }

    }
    
    /* добавление брони запись sub rotes */
    const handleReserve = async (trip) => {
        let tripStart = new Date(trip.dateTimeStart)
        tripStart = moment(tripStart, "YYYY-MM-DD HH:mm:ss");
        tripStart.add(stop.timeOffSet, "minutes");
        tripStart = tripStart.format("YYYY-MM-DD HH:mm:ss")
        try {
            await createOrder({ dateTimeStart: tripStart, seats: seat, comment: comment, startStopId: stop.id, tripId: trip.id, userId: userId, typePay: 'Наличными' })
            openModal('Поездка забронирована! Спасибо!')
        } catch (error) {
            alert(error.response.data.message);
        }
    }

    const handleReserveWithPoints = async (trip) => {
        let tripStart = new Date(trip.dateTimeStart)
        tripStart = moment(tripStart, "YYYY-MM-DD HH:mm:ss");
        tripStart.add(stop.timeOffSet, "minutes");
        tripStart = tripStart.format("YYYY-MM-DD HH:mm:ss")
        try {
            await createOrder({ dateTimeStart: tripStart, seats: seat, comment: comment, startStopId: stop.id, tripId: trip.id, userId: userId, typePay: 'Баллами' })
            await payByPoints({ userId: userId, subRouterId: trip.id })
            openModal('Поездка забронирована баллами! Спасибо!')
        } catch (error) {
            alert(error.response.data.message);
        }
    }

    function handleChangePaymentCache() {
        setPaymentCache(true);
        setPaymentPoints(false);
    }

    function handleChangePaymentPoints() {
        setPaymentCache(false);
        setPaymentPoints(true);
    }

    const handleNumberInputChange = (e) => {
        setNumberInputValue(e.target.value);

        setNumberInput(e.target.value.replace(/[\s()-]/g, ''))
    };

    function generateCode() {
        let result = '';
        for (let i = 0; i < 4; i++) {
            result += Math.floor(Math.random() * 10);
        }
        setCodeSMS(result)
        return result
    }

    async function continueLogin() {
        try {
            const response = await getOneByPhone(numberInput);
    
            console.log(response);
    
            if (numberInput.length < 13) {
                setErrorMessage('Неверный пароль');
                return;
            }
           /*  if (!nameInput) {
                setErrorMessage('Заполните имя')
                return;
            }
            if (!sureNameInput || sureNameInput.length < 3) {
                setErrorMessage('Фамилия должна содержать минимум 3 символа')
                return;
            } */
            if (response.isBlocked === true) {
                setErrorMessage('Ваш аккаунт заблокирован');
                return;
            }
    
            if (response.id) {
                setIsShowSMS(true);
                setIsLogin(true);
    
                const code = generateCode();
                const currentNumber = numberInput.slice(1);
    
                const params = new URLSearchParams();
                params.append('username', smsUsername);
                params.append('password', smsPassword);
                params.append('phone', currentNumber);
                params.append('text', `Ваш код подтверждения: ${code}`);
    
                fetch('https://api.rocketsms.by/simple/send?' + params, {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    setIsShowContinue(false);
                    setIsShowLoginBtn(true);
                    setErrorMessage('');
                })
                .catch(error => {
                    setErrorMessage(error.response.data.message);
                });
            } else {
                setIsShowNameInput(true);
                setIsShowSureNameInput(true);
                setIsLogin(false);
                setIsShowContinueReg(true);
                setIsShowContinue(false);
            }
        } catch (error) {
          
                console.error('Ошибка при запросе на сервер:', error);

                setIsShowNameInput(true);
                setIsShowSureNameInput(true);
                setIsLogin(false);
                setIsShowContinueReg(true);
                setIsShowContinue(false);
        }
    }
    

    async function login() {
        if (isLogin) {
            if (SMSInput === codeSMS) {
                loginUser(numberInput)
                    .then(data => {
                        dispatch(changeId(data.id))
                        dispatch(changeName(data.name))
                        dispatch(changeNumber(data.phoneNumber))
                        dispatch(changeRole(data.role))
                        dispatch(changeBonuces(data.points))
                        dispatch(changeIsBlocked(data.isBlocked))
                        setIsShowSMS(false);
                        setErrorMessage('');
                        setIsShowContinue(false);
                        setIsShowLoginBtn(false);
                    })
                    .catch(e => {
                        setErrorMessage(e.message);
                    })
            } else {
                setErrorMessage('Неверный код')
            }
        } else if (!isLogin) {
            if (SMSInput === codeSMS) {
                const data = await registration(`${nameInput} ${sureNameInput}`, numberInput);

                dispatch(changeId(data.id))
                dispatch(changeName(data.name))
                dispatch(changeNumber(data.phoneNumber))
                dispatch(changeRole(data.role))
                dispatch(changeBonuces(data.points))
                dispatch(changeIsBlocked(data.isBlocked))
                setErrorMessage('');
                setNumberInput('');
                setSureNameInput('');
                setSMSInput('');
            } else {
                setErrorMessage('Неверный код')
            }
        }
    }

    function continueReg() {
        
        if (!nameInput) {
            setErrorMessage('Заполните имя')
            return;
        }
        if (!sureNameInput || sureNameInput.length < 3) {
            setErrorMessage('Фамилия должна содержать минимум 3 символа')
            return;
        }

        setIsShowSMS(true);
        setIsLogin(false);

        const code = generateCode()
        const currentNumber = numberInput.slice(1);

        const params = new URLSearchParams();
        params.append('username', smsUsername);
        params.append('password', smsPassword);
        params.append('phone', currentNumber);
        params.append('text', `Ваш код подтверждения: ${code}`);

        fetch('https://api.rocketsms.by/simple/send?' + params, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                setIsShowContinueReg(false);
                setIsShowLoginBtn(true);
                setErrorMessage('');
            })
            .catch(error => {
                setErrorMessage(error.response.data.message)
            });
    }


    return (
        <div className="container">
            <div className="reservationTickets__content">
                {(stopName && trips) && trips.map((trip) => {
                    let theDisabled = '';
                    let tripEnd = new Date(trip.dateTimeFinish)
                    tripEnd = moment(tripEnd, "YYYY-MM-DD HH:mm:ss");
                    tripEnd = tripEnd.format("HH:mm")
                    // tripEnd = `${tripEnd.split(':')[0]}:${tripEnd.split(':')[1]}`


                    let tripStart = new Date(trip.dateTimeStart)
                    if (tripStart < new Date()) {
                        theDisabled = true;
                    }
                    tripStart = moment(tripStart, "YYYY-MM-DD HH:mm:ss");

                    tripStart.add(stop.timeOffSet, "minutes");
                    tripStart = tripStart.format("HH:mm")
                    // tripStart = `${tripStart.split(':')[0]}:${tripStart.split(':')[1]}`

                    const startTime = new Date('1970-01-01T' + tripStart + 'Z');
                    const endTime = new Date('1970-01-01T' + tripEnd + 'Z');

                    const timeDiff = endTime - startTime;
                    const hours = Math.floor((timeDiff % 86400000) / 3600000);
                    const minutes = Math.round(((timeDiff % 86400000) % 3600000) / 60000);

                    const difference = `${hours} часа ${minutes} мин`;


                    return <div key={trip.id} className={`reservationTickets__card ${disabledArr.includes(trip.id) || theDisabled ? 'reservationTickets__card-disabled' : ''}`}>
                        <div onClick={() => { isToShow === trip.id ? setIsToShow('') : setIsToShow(trip.id) }} className="reservationTickets__card-header">
                            <div className="reservationTickets__card-header-left">
                                <div className="departure-arrival">
                                    <div className="time">{tripStart}</div>
                                    <div className="place">{stopName}</div>
                                </div>

                                <div className="departure-difference">
                                    <p className="departure-difference-text">{difference}</p>
                                </div>

                                <div className="departure-arrival">
                                    <div className="time">{tripEnd}</div>
                                    <div className="place">{routeName}</div>
                                </div>
                            </div>
                            <div className="reservationTickets__card-header-right">
                                <p className="trip_price">{priceValue}р.00к.</p>
                            </div>
                        </div>
                        {isToShow === trip.id && <div className="reservationTickets__card-details">
                            
                            <div className="detail-row"><span className="detail-row-selected">Маршрут:</span> {route.id === 2 ? `${routes[0].name} → ${routes[1].name}` : `${routes[1].name} → ${routes[0].name}`}</div>
                            <div className="detail-row"><span className="detail-row-selected">Заберем на:</span> {stopName}</div>
                            <div className="detail-row"><span className="detail-row-selected">Мест:</span> {seat}</div>

                            <div className="select_payment-wrap">
                                <div className="select_payment-item_wrap">
                                    <input type="checkbox" checked={paymentCache} onChange={handleChangePaymentCache} className="select_payment-item" />
                                    <p className="select_payment-item_text">Оплата наличными</p>
                                </div>
                                <div className="select_payment-item_wrap">
                                    <input type="checkbox" checked={paymentPoints} onChange={handleChangePaymentPoints} className="select_payment-item" />
                                    <p className="select_payment-item_text">Оплата бонусами</p>
                                </div>
                            </div>

                            {
                                !isLogged ?
                                    <div className="ticket__login-form">
                                        <InputMask className='login-input test' value={numberInputValue} onChange={(e) => { handleNumberInputChange(e) }} placeholder="Введите ваш телефон" mask="+375 (99) 999-99-99" maskChar="_" />
                                        {isShowNameInput && <input
                                            type="text"
                                            value={nameInput}
                                            onChange={(e) => { setNameInput(e.target.value) }}
                                            placeholder="Введите ваше имя"
                                            className='login-input'
                                        />}
                                        {isShowSureNameInput && <input
                                            type="text"
                                            value={sureNameInput}
                                            onChange={(e) => { setSureNameInput(e.target.value) }}
                                            placeholder="Введите вашу фамилию"
                                            className='login-input'
                                        />}
                                        {isShowSMS && <input
                                            type="text"
                                            value={SMSInput}
                                            onChange={(e) => { setSMSInput(e.target.value) }}
                                            placeholder="Введите код из СМС"
                                            className='login-input'
                                        />}
                                        {errorMessage && <p className='login__error-text'>{errorMessage}</p>}
                                        {isShowContinue && <button onClick={continueLogin} className="reserve-btn ticket__reserve-btn">Продолжить</button>}
                                        {isShowContinueReg && <button onClick={continueReg} className="reserve-btn ticket__reserve-btn">Продолжить</button>}
                                        {isShowLoginBtn && <button onClick={login} className="reserve-btn ticket__reserve-btn">{isLogin ? 'Войти' : 'Зарегестрироваться'}</button>}
                                    </div>
                                    : isLogged && role === "DISPETCHER" ?
                                        <div className="detail__form-reserve">

                                            <div>Имя <input className="detail__form-input" type="text" value={passengerName} placeholder="Имя" onChange={(e) => { setPassengerName(e.target.value) }} /></div>
                                            {/* <InputMask className='login-input' value={numberFromDispetcher} onChange={handleChangeNumber} placeholder="Введите ваш телефон" mask="+375 (99) 999-99-99" maskChar="_" /> */}
                                            <div>+375<input className="detail__form-input" type="text" value={numberFromDispetcher} placeholder="Номер телефона" onChange={handleChangeNumber} /></div>
                                            <textarea className="detail__form-textarea" type="text" value={comment} placeholder="Комментарий.." onChange={(e) => { setComment(e.target.value) }} />
                                            <button onClick={() => { handleReserveDispetcher(trip) }} className="reserve-btn">Бронировать</button>
                                        </div>
                                        : isLogged && paymentCache ?
                                            <div className="form_submit">
                                                <textarea className="detail__form-textarea" type="text" value={comment} placeholder="Комментарий.." onChange={(e) => { setComment(e.target.value) }} />
                                                <button onClick={() => { handleReserve(trip) }} className="reserve-btn">Бронировать</button>
                                            </div>
                                            : isLogged && paymentPoints && points >= FreeRideValue ?
                                                <div className="form_submit">
                                                    <textarea className="detail__form-textarea" type="text" value={comment} placeholder="Комментарий.." onChange={(e) => { setComment(e.target.value) }} />
                                                    <button onClick={() => { handleReserveWithPoints(trip) }} className="reserve-btn">Бронировать</button>
                                                </div>
                                                : isLogged && paymentPoints && points <= FreeRideValue ?
                                                    <div className="ticket_noPoints-wrap">
                                                        <p className="tiket-text1">У вас <span className="ticket-text-selected">{points}</span> бонусов</p>
                                                        <p className="tiket-text2">Бонусы мы начисляем за каждую отплаченную поездку. <span className="ticket-text-selected">1р = 5 бонусов</span>. После завершения каждой поездки, на баланс вашего аккаунта будет начисленно <span className="ticket-text-selected">{SuccessValue} бонусов</span>, которые вы сможете использовать на бесплатную поездку.</p>
                                                        <p className="tiket-text3">{FreeRideValue} бонусов = 1 бесплатная поездка</p>
                                                        <p className="tiket-text4">Если вы отменили поездку позднее чем <span className="ticket-text-selected">за 2 часа</span> до отправления, у вас на балансе сгорит <span className="ticket-text-selected">{UnSuccessValue} бонусов</span>. В случае если вы не отменили поездку и не явились на посадку, с вашего баланса будет списано <span className="ticket-text-selected">200 бонусов</span>. В иных случаях бонусы не сгорают.</p>
                                                        <p className="tiket-text4">За счет баллов может быть забронирована 1 поездка на 1 пассажира.
                                                            Возможность сделать бронь за счет баллов восстанавливается после совершения поездки за баллы или отмены такой брони.
                                                            За поездку, оплаченную баллами, не начисляются новые баллы. Бронирование поездки за баллы не влияет на начисление баллов за другие поездки.
                                                        </p>
                                                    </div>
                                                    :
                                                    ''
                            }
                        </div>}
                    </div>
                })}
                {
                    isLoading ?
                        <div className="loading">Загрузка...</div>
                        :
                        (stopName && Object.keys(trips).length === 0) && <p className="reservationTickets__noContent-text">
                            На этот день рейсов нет.
                        </p>
                }

            </div>
            <Modal visible={showModal} setVisible={closeModal}>{modalMessage}</Modal>
        </div>
    )
}

export default ReservationTickets